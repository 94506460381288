/* Dark mode button */

.dark-mode-btn {
    display: flex;
    justify-content: space-between;
    width: 51px;
    height: 26px;
    background-color: #272727;
    border-radius: 50px;
    padding: 5px;
    position: relative;
    order: 9;
}

.dark-mode-btn::before {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    transition: left 0.2s ease-in;
}

.dark-mode-btn--active::before {
    left: 26px;
}

.dark-mode-btn__icon {
    position: relative;
}