/* NAV */ 

.nav {
    background-color: var(--dark-bg);
    color: var(--header-text);
    padding: 20px 0px;
    border-bottom: 1px solid var(--dark-border);
    letter-spacing: normal;
}

.nav-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 40px;
    row-gap: 20px;
    flex-wrap: wrap;
}

.logo {
    font-family: 'Poppins', sans-serif; 
    color: var(--header-text);
    font-size: 24px;
    margin-right: auto;
}

.nav-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 40px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif; 
}

.nav-list__link {
    color: var(--header-text);
    transition: opacity 0.2s ease-in;
}

.nav-list__link:hover {
    opacity: 0.8;
}

.nav-list__link--active {
    position: relative;
}

.nav-list__link--active::before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    display: block;
    height: 2px;
    width: 100%;
    background-color: var(--accent);
}