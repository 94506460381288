/* Footer */

.footer {
    background-color: var(--dark-bg);
    padding: 60px 0px 50px;
    color: var(--white);
    margin-top: auto;
}

.footer__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 27px;
}

.social {
    display: flex;
    column-gap: 30px;
    align-items: center;
}

.copyright {
    font-size: 16px;
}