/* Header */

.header {
    background-color: var(--dark-bg);
    background-image: url(../../img/header-bg.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    color: var(--header-text);
    min-height: 695px;
    padding: 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.header__wrapper {
    padding: 0px 15px;
    max-width: 660px;
}

.header__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 20px;
}

.header__title strong {
    font-size: 60px;
}

.header__title em {
    font-style: normal;
    color: var(--accent);
}

.header__text {
    font-size: 18px;
    line-height: 1.333;
    margin-bottom: 40px;
}

.header__text p + p {
    margin-top: 0.5em;
}